import React from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { PDFDocument } from 'pdf-lib'

import Layout from 'layouts/index'
import TitleBox from 'components/TitleBox'
import Button from 'components/Button'
import encaminhamentoLetter from 'files/encaminhamento.pdf'

import { PageContainer } from 'pageStyles/sobre-nos.styles'

import {
  FormContainer,
  Text,
  InputContainer,
  Label,
  FormInput,
  ErrorText,
  FormTextArea,
  FormSelectExam,
  FormSelectCheckbox,
  FormCheckbox,
  FormRadio,
  FormText,
  Row,
} from 'pageStyles/carta-encaminhamento.styles'

interface Inputs {
  name: string // o
  weight: string // o
  specie: string
  age: number // o
  ownerName: string // o
  phone: string
  examType: 'interment' | 'surgery' | 'exam' // o, min 1
  internament: 'marked'
  surgery_checkbox: 'marked'
  exam: 'marked'
  examSpecification: string
  fc: string
  fr: string
  t: string
  losses: 'vomit' | 'diarrhea'
  alimentation: 'alimentation_yes' | 'alimentation_no'
  vaccines: 'vaccines_yes' | 'vaccines_no'
  examsDone: string
  medicationsApplied: string
  clinicSuspects: string // ojjjj
  observations: string
  surgery: string
  medic: string // o
  clinic: string
  contact: string
  clinicStateReturn: 'clinicStateReturn_yes' | 'clinicStateReturn_no'
  phoneNumberForContact: string
}

const inputToForm = {
  name: 'nome_do_animal',
  weight: 'peso',
  specie: 'especie',
  age: 'idade',
  ownerName: 'nome_do_prorietario',
  phone: 'telefone',
  internament: 'internamento',
  surgery_checkbox: 'cirurgia',
  exam: 'exame',
  examSpecification: 'exames_especificar',
  fc: 'fc',
  fr: 'fr',
  t: 't',
  vomit: 'vomito',
  diarrhea: 'diarreia',
  alimentation_yes: 'alimentacao_sim',
  alimentation_no: 'alimentation_nao',
  vaccines: 'vacinas',
  vaccines_yes: 'vacinas_sim',
  vaccines_no: 'vacinas_nao',
  examsDone: 'exames_realizados',
  medicationsApplied: 'medicacoes_aplicadas',
  clinicSuspects: 'suspeita_clinica',
  observations: 'observacoes',
  surgery: 'cirurgia_2',
  medic: 'medico_veterinario_crmv',
  clinic: 'clinica_consultorio',
  contact: 'contato',
  clinicStateReturn_yes: 'retorno_sim',
  clinicStateReturn_no: 'retorno_nao',
  phoneNumberForContact: 'celular',
}

const inverted = Object.assign(
  {},
  ...Object.entries(inputToForm).map(([a, b]) => ({ [b]: a }))
)

const CartaEncaminhamento = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = data => {
    fetch(encaminhamentoLetter)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader()
        const pdfBlob = new Blob([blob as BlobPart], {
          type: 'application/pdf',
        })
        reader.readAsArrayBuffer(pdfBlob)
        reader.onload = async () => {
          if (reader.result) {
            const pdfDoc = await PDFDocument.load(reader.result)
            const form = pdfDoc.getForm()
            const fields = form.getFields()
            fields.forEach(field => {
              const name = field.getName()
              try {
                const textToFill = form.getTextField(name)
                textToFill.setText(data[inverted[name]])
              } catch (e) {}

              try {
                const checkToFill = form.getCheckBox(name)
                if (data[inverted[name]] === 'marked') checkToFill.check()
                if (Object.values(data).some(el => el === inverted[name]))
                  checkToFill.check()
              } catch (e) {}
            })
            const pdfBytes = await pdfDoc.save()
            const pdfFormBlob = new Blob([pdfBytes], {
              type: 'application/pdf',
            })
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(
                pdfFormBlob,
                'carta_encaminhamento'
              )
            } else {
              var a = document.createElement('a')
              document.body.appendChild(a)
              var csvUrl = URL.createObjectURL(pdfFormBlob)
              a.href = csvUrl
              a.download = 'cata_encaminhamento'
              a.click()
              URL.revokeObjectURL(a.href)
              a.remove()
            }
          }
        }
      })
  }

  return (
    <Layout>
      <>
        <TitleBox title='Carta de Encaminhamento' bg='' />
        <Text>
          Preencha os campos abaixo e clique em 'Confirmar' para fazer o
          download da carta.
        </Text>
        <PageContainer>
          <FormContainer onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <InputContainer>
                <Label>Nome*</Label>
                <FormInput
                  type='text'
                  {...register('name', { required: true })}
                />
                {errors.name && errors.name.type === 'required' && (
                  <ErrorText>Campo obrigatório</ErrorText>
                )}
              </InputContainer>
              <InputContainer>
                <Label>Peso(kg)*</Label>
                <FormInput
                  type='number'
                  {...register('weight', { required: true })}
                />
                {errors.weight && errors.weight.type === 'required' && (
                  <ErrorText>Campo obrigatório</ErrorText>
                )}
              </InputContainer>
            </Row>
            <Row>
              <InputContainer>
                <Label>Espécie</Label>
                <FormInput {...register('specie')} />
              </InputContainer>
              <InputContainer>
                <Label>Idade*</Label>
                <FormInput
                  type='number'
                  {...register('age', { required: true })}
                />
                {errors.age && errors.age.type === 'required' && (
                  <ErrorText>Campo obrigatório</ErrorText>
                )}
              </InputContainer>
            </Row>
            <Row>
              <InputContainer>
                <Label>Nome do proprietário*</Label>
                <FormInput {...register('ownerName', { required: true })} />
                {errors.ownerName && errors.ownerName.type === 'required' && (
                  <ErrorText>Campo obrigatório</ErrorText>
                )}
              </InputContainer>
              <InputContainer>
                <Label>Telefone</Label>
                <FormInput type='phone' {...register('phone')} />
              </InputContainer>
            </Row>
            <Row>
              <FormSelectExam>
                <Label>Internamento</Label>
                <FormCheckbox
                  type='checkbox'
                  value='marked'
                  {...register('internament')}
                />
                <Label>Cirurgia</Label>
                <FormCheckbox
                  type='checkbox'
                  value='marked'
                  {...register('surgery_checkbox')}
                />
                <Label>Exame</Label>
                <FormCheckbox
                  type='checkbox'
                  value='marked'
                  {...register('exam')}
                />
              </FormSelectExam>
            </Row>
            <Row>
              <FormText>
                Para isenção do valor da consulta e auxílio na continuidade do
                atendimento clínico, favor preencher os dados abaixo:
              </FormText>
            </Row>
            <Row>
              <InputContainer>
                <Label>F.C</Label>
                <FormInput {...register('fc')} />
              </InputContainer>
              <InputContainer>
                <Label>F.R</Label>
                <FormInput {...register('fr')} />
              </InputContainer>
              <InputContainer>
                <Label>T</Label>
                <FormInput {...register('t')} />
              </InputContainer>
            </Row>

            <FormSelectCheckbox>
              <Row>
                <Label>Perdas:</Label>
                <FormCheckbox
                  type='radio'
                  value='vomit'
                  {...register('losses')}
                />
                <Label>Vômito</Label>
                <FormRadio
                  type='radio'
                  value='diarrhea'
                  {...register('losses')}
                />
                <Label>Diarréia</Label>
              </Row>
              <Row>
                <Label>Alimentação:</Label>
                <FormCheckbox
                  type='radio'
                  value='alimentation_yes'
                  {...register('alimentation')}
                />
                <Label>Sim</Label>
                <FormRadio
                  type='radio'
                  value='alimentation_no'
                  {...register('alimentation')}
                />
                <Label>Não</Label>
              </Row>
              <Row>
                <Label>Vacinas:</Label>
                <FormRadio
                  type='radio'
                  value='vaccines_yes'
                  {...register('vaccines')}
                />
                <Label>Sim</Label>
                <FormRadio
                  type='radio'
                  value='vaccines_no'
                  {...register('vaccines')}
                />
                <Label>Não</Label>
              </Row>
            </FormSelectCheckbox>

            <Row>
              <InputContainer>
                <Label>Exames Realizados(anexar)</Label>
                <FormInput {...register('examsDone')} />
              </InputContainer>
            </Row>
            <Row>
              <InputContainer>
                <Label>Medicações Aplicadas</Label>
                <FormTextArea {...register('medicationsApplied')} />
              </InputContainer>
            </Row>
            <Row>
              <InputContainer>
                <Label>Suspetia Clínica</Label>
                <FormTextArea {...register('clinicSuspects')} />
              </InputContainer>
            </Row>
            <Row>
              <InputContainer>
                <Label>Observações</Label>
                <FormTextArea {...register('observations')} />
              </InputContainer>
            </Row>
            <Row>
              <InputContainer>
                <Label>Cirurgia</Label>
                <FormTextArea {...register('surgery')} />
              </InputContainer>
            </Row>
            <Row>
              <InputContainer>
                <Label>Médico Veterinário requisitante e CRMV*</Label>
                <FormInput {...register('medic', { required: true })} />
                {errors.medic && errors.medic.type === 'required' && (
                  <ErrorText>Campo obrigatório</ErrorText>
                )}
              </InputContainer>
            </Row>
            <Row>
              <InputContainer>
                <Label>Clínica/Consultório</Label>
                <FormInput {...register('clinic')} />
              </InputContainer>
            </Row>
            <Row>
              <InputContainer>
                <Label>Contato (email/telefone)</Label>
                <FormInput {...register('contact')} />
              </InputContainer>
            </Row>
            <Row>
              <InputContainer>
                <Label>Gostaria de retorno do estado clínico do animal?</Label>
                <Label>Sim</Label>
                <FormRadio
                  type='radio'
                  value='clinicStateReturn_yes'
                  {...register('clinicStateReturn')}
                />
                <Label>Não</Label>
                <FormRadio
                  type='radio'
                  value='clinicStateReturn_no'
                  {...register('clinicStateReturn')}
                />
              </InputContainer>
            </Row>
            <Row>
              <InputContainer>
                <Label>Número de celular, para contato via whatsapp</Label>
                <FormInput
                  type='phone'
                  {...register('phoneNumberForContact')}
                />
              </InputContainer>
            </Row>
            <Row>
              <Button>Confirmar</Button>
            </Row>
          </FormContainer>
        </PageContainer>
      </>
    </Layout>
  )
}

export default CartaEncaminhamento
